<template>
  <div>

    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-6">
              <h1 class="title is-3 is-marginless">{{ $t('SUPPORT') }}</h1>
              <p><span v-html="$t('Please read through')"></span>.</p>
              <div style="height:0.75rem;"></div>
              <div class="field">
                <label class="label">{{ $t('What should We call You') }}?*</label>
                <div class="control">
                  <input v-model.trim="fio" name="fio"  v-validate="'required'" class="input" type="text" :class="{'has-error': errors.has('fio')}">
                  <p v-if="errors.has('fio')" class="alert-error ">{{errors.first('fio')}}</p>
                </div>
              </div>
              <div class="field">
                <label class="label">Email*</label>
                <div class="control">
                  <input v-model.trim="email" name="email" v-validate:email="'required|email'" class="input" type="email" :class="{'has-error': errors.has('email')}">
                  <p v-if="errors.has('email')" class="alert-error ">{{errors.first('email')}}</p>
                </div>
              </div>
              <div class="field">
                <label class="label">{{ $t('Order number') }}</label>
                <div class="control">
                  <input v-model="order" class="input" type="text" name="order">
                </div>
              </div>
              <div style="height:0.75rem;"></div>
              <div class="field">
                <label class="label">{{ $t('Subject') }}*</label>
                <div class="control">
                  <input v-model="subject" name="subject" v-validate="'required'" class="input" type="text" :class="{'has-error': errors.has('subject')}">
                  <p v-if="errors.has('subject')" class="alert-error ">{{errors.first('subject')}}</p>
                </div>
              </div>
              <div class="field">
                <label class="label">{{ $t('Message') }}*</label>
                <div class="control">
                  <textarea v-model="message" name="message" v-validate="'required'" class="textarea" :class="{'has-error': errors.has('message')}"></textarea>
                  <p v-if="errors.has('message')" class="alert-error ">{{errors.first('message')}}</p>
                </div>
              </div>
              <div class="file has-name is-primary">
                <label class="file-label">
                  <input name="file" class="file-input" type="file" @change="processFile">
                  <input name="fileName" type="hidden" :value="{inptFileName}"/>
                  <input name="fileBlob" type="hidden" :value="{inptFileBlob}"/>
                  <span class="file-cta">
                    <span class="file-icon"><img src="@/assets/icons/attach.svg"></span>
                    <span class="file-label">{{ $t('Attach a document') }}</span>
                  </span>
                  <span class="file-name" :class="{'has-text-danger': isTextDanger, 'has-text-primary': isTextPrimary}">{{fileText}}</span>
                </label>
              </div>
              <div style="height:1.5rem;"></div>

              <div class="control">
                  <a class="button is-primary is-medium" :class="{'is-loading': isLoading}" :disabled="valid" v-on:click="Submit">{{ $t('Send') }}</a>
              </div>

              <div style="height:1.5rem;"></div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: 'SupportForm',
  data () {
    return {
      fio: null,
      email: null,
      order: null,
      subject: null,
      message: null,
      inptFileName: null,
      inptFileBlob: null,
      isTextDanger: true,
      isTextPrimary: false,
      fileLabel: null,
      isLoading: false,
      valid: false
    }
  },
  computed: {
    // fileText: {
    //   get () {
    //     return this.$i18n.t('No document selected').toString()
    //   },
    //   set (newValue) {
    //     console.log(newValue)
    //     return newValue
    //   }
    // }
    fileText () {
      return this.fileLabel ? this.fileLabel : this.$i18n.t('No document selected').toString()
    }
  },
  methods: {
    processFile (event) {
      if (event) {
        const file = event.target.files[0]
        if (file) {
          if (file.size > 2097152) {
            this.fileLabel = this.$i18n.t('File size should not exceed 2MB')
            this.inptFileName = null
            this.inptFileBlob = null
            this.isTextDanger = true
            this.isTextPrimary = false
          } else {
            this.fileLabel = file.name
            this.isTextDanger = false
            this.isTextPrimary = true
            const reader = new FileReader()
            reader.onload = e => this.inptFileBlob = (e.target.result).split(',')[1]
            reader.readAsDataURL(file)
            this.inptFileName = file.name
          }
        } else {
          this.fileLabel = this.$i18n.t('No document selected')
          this.inptFileName = null
          this.inptFileBlob = null
          this.isTextDanger = true
          this.isTextPrimary = false
        }
      }
    },
    Submit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.valid = true
          this.isLoading = true
          this.$http.post('/certificate/support', { fio: this.fio, email: this.email, order: this.order, subject: this.subject, message: this.message, fileName: this.inptFileName, fileBlob: this.inptFileBlob }).then(response => {
            if (response.data === 'ok') {
              this.$router.push({ name: 'Home' })
              this.$notification.new(this.$i18n.t('Your message has been successfully sent'), { timer: 10 })
            } else {
              this.$notification.error(response.data, { timer: 10 })
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .has-error {
    border-color: red;
  }
  .alert-error {
    color: red;
  }
  .result {
    padding-top: 100px;
  }
  .file-name {
    max-width: 25em !important;
  }
</style>
